import {EnvironmentTypes} from '../app/Enums/environment-types';

export const environment = {
    tenant: EnvironmentTypes.MSS,
    production: true,
    redirectUri: 'https://arp-ss.schaufler-metalle.com/',
    postLogoutRedirectUri: 'https://arp-ss.schaufler-metalle.com/',
    arpApiUrl: 'https://arp-ss-api.azurewebsites.net',
    portalUrl: 'https://arp.schaufler-metalle.com/?autoredirect=false',
    msalAuthority: 'https://login.microsoftonline.com/a76381c7-ea6e-41f4-8bb8-cb6ec292a8a0',
    msalClientId: 'cccbee91-c7a4-4ad9-b791-d09678fcc0ba',
    authScopes: [
        {
            apiUrl: 'https://arp-ss-api.azurewebsites.net/v2_0',
            scope: 'api://d5a6c95d-9e7a-4bc2-a9c5-007353fc5578/Api.ReadWrite.All'
        }
    ]
};
