import {Component, OnInit} from '@angular/core';
import {APIService} from '../../Services/api.service';
import {Router} from '@angular/router';
import {DataShareService} from '../../Services/data-share.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import * as moment from 'moment';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {ExecutionActionTypes} from '../../Enums/execution-action-types.enum';
import {Subject} from 'rxjs';
import {UserAccessService} from '../../Services/user-access.service';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {error} from 'protractor';
import {ProcessTypes} from '../../Enums/process-types.enum';
import {ThemePalette} from '@angular/material/core';
import {environment} from "../../../environments/environment";
import {EnvironmentTypes} from "../../Enums/environment-types";

moment.locale('de');

moment.locale('de');

@Component({
    selector: 'app-execution-screen-vsa-book',
    templateUrl: './treatment-book.component.html',
    styleUrls: ['./treatment-book.component.scss']
})
export class TreatmentBookComponent implements OnInit {
    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;

    public Batch: any;
    public productCalculation: any[];
    public productOutputTotal: number;
    public ressourceCost: any[];
    public humansResources: any[];
    public machinesResources: any[];
    public inputPrice = 0.00;
    public batchQuantity = 0.00;
    public additionalCosts = 0;
    public additionalCostsName = '';
    public commentText = '';
    dateFormat = 'dd.MM.yyyy, HH:mm:ss';
    color: ThemePalette = 'primary';


    invoicesList: any;
    displayedColumns: string[] = ['Zeit', 'Material', 'Fahrzeug Name', 'Fahrzeug Tara', 'Container Name', 'Container Tara', 'Waage', 'Brutto', 'Netto', 'Username'];

    public TreatmentErrors = [{
        code: 'TREAT-001',
        name: 'Allgemeiner Fehler beim Buchen'
    },
        {
            code: 'TREAT-002',
            name: 'Aufbereitung bereits gebucht'
        },
        {
            code: 'TREAT-003',
            name: 'Artikel nicht gefunden'
        },
        {
            code: 'TREAT-004',
            name: 'Artikel bereits ausgebucht'
        },
        {
            code: 'TREAT-005',
            name: 'Menge zu groß'
        },
        {
            code: 'TREAT-006',
            name: 'Material wurde nicht vollständig ausgebucht'
        }
    ];

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public enableAdditionalCostsInput = false;
    public overheadCosts: number;
    public energyPrice = 0;


    constructor(public apiService: APIService,
                public router: Router,
                public dataService: DataShareService,
                public dialog: MatDialog,
                public userAccess: UserAccessService) {
        if (this.dataService.BatchToBook) {
            console.log(this.dataService.BatchToBook);
            this.Batch = this.dataService.BatchToBook;
            if (this.Batch.process.process_category === 'processing') {
                this.Batch.process.process_category_to_show = ProcessTypes.AUFBEREITUNG;
            } else if (this.Batch.process.process_category === 'maintenance') {
                this.Batch.process.process_category_to_show = ProcessTypes.INSTANDHALTUNG;
            } else {
                this.Batch.process.process_category_to_show = ProcessTypes.ZERKLEINERUNG;
            }
            this.getEnergyFromAPI();
            this.apiService.getBatchComment(this.Batch.id).subscribe((data: any) => {
                if (data) {
                    if (data.text) {
                        this.commentText = data.text;
                    } else {
                        this.apiService.getProcessComment(this.Batch.process_id).subscribe((data2: any) => {
                            if (data2) {
                                this.commentText = data.text;
                            }
                        });
                    }
                }
            });
            this.apiService.getAdditionalCosts(this.Batch.id).subscribe((data: any) => {
                if (data) {
                    this.additionalCosts = Number(data.value.toFixed(2));
                    this.additionalCostsName = data.text;
                    this.enableAdditionalCostsInput = true;
                } else {
                    this.enableAdditionalCostsInput = true;
                }
            });
            this.batchQuantity = this.Batch.process.process_category === 'maintenance' ? 0 : this.Batch.quantity;
            this.invoicesList = this.dataService.BatchToBook.weighings;
            this.invoicesList.forEach(el => el.time = moment.utc(el.time).local());
            this.getProductCalculation();
            this.getProductTotalValue();
            this.getRessourceOutput();
        } else {
            this.router.navigate(['/home']);
        }
    }


    ngOnInit(): void {

    }

    public getEnergyFromAPI() {
        this.apiService.getEnergyCosts().subscribe({
            next: (energyCosts) => {
                this.handleReceivedEnergy(energyCosts);
            }
        });
    }

    private handleReceivedEnergy(data: any) {
        this.overheadCosts = data.energy_overhead;
        this.energyPrice = data.energy_costs;
    }

    onkeyUp($event) {
        if ($event.target.value > 999999) {
            $event.target.value = null;
            this.additionalCosts = null;
        }
        if ($event.target.value < 0) {
            $event.target.value = null;
            this.additionalCosts = null;
        }
        const commaPlace = $event.target.value.toString().indexOf('.');
        if (commaPlace !== -1) {
            const substringAfterComma = $event.target.value.toString().substring(commaPlace + 1);
            let newNumber;
            if (substringAfterComma.length > 2) {
                newNumber = $event.target.value.toString().slice(0, -1);
                $event.target.value = parseFloat(newNumber);
                this.additionalCosts = parseFloat(newNumber);
            }
        }

    }

    public getBruttoWeight(netto, taramover, taracontainer) {
        return netto + taramover + taracontainer;
    }


    public getProductCalculation() {
        this.productCalculation = [];
        if (this.Batch) {
            if (this.Batch.output_weights) {
                for (const key of Object.keys(this.Batch.output_weights)) {
                    if (this.Batch.output_weights[key]) {
                        const wg = this.Batch.output_weights[key];

                        this.productCalculation.push({
                            id: key,
                            name: wg.name,
                            weight: wg.weight,
                            price: 0
                        });
                    }
                }
            }
        }
    }

    public getProductTotalQuantity(): number {
        let result = 0;

        for (const pr of this.productCalculation) {
            result += pr.weight;
        }
        this.getProductTotalValue();
        return result;
    }

    public getProductTotalValue() {
        let result = 0;

        for (const pr of this.productCalculation) {
            result += (pr.weight * pr.price);
        }
        this.productOutputTotal = result;
    }

    public getDeltaQuantity() {
        return this.batchQuantity - this.getProductTotalQuantity();
    }

    GetCurrentSumTime(): number {

        let result = 0;
        if (this.Batch) {
            if (this.Batch.executions) {
                // SUM ALL
                let seconds = 0.0;
                for (const et of this.Batch.executions.filter(ex => ex.ended)) {
                    const start = moment(et.started);
                    const end = moment(et.ended);

                    seconds += end.diff(start, 'seconds');
                }


                // BREAK TIME
                const executions = this.Batch.executions.filter(ex => ex.ended).sort((a, b) => (a.ended < b.ended ? -1 : 1));
                if (executions.length > 1) {
                    let lastEnded = executions[0].ended;
                    for (const e of executions) {
                        if (lastEnded !== e.ended) {
                            const start = moment(lastEnded);
                            const end = moment(e.started);
                            seconds += end.diff(start, 'seconds');
                            lastEnded = e.ended;
                        }
                    }
                }
                result = seconds / 3600.0;
            }
        }
        return result;
    }

    public getRessourceOutput() {

        this.ressourceCost = [];
        this.humansResources = [];
        this.machinesResources = [];

        // GetTotalHours
        const totalHours = this.Batch.execution_time / 3600; // this.GetCurrentSumTime();

        // let times = this.getTotalTimes();
        // times = times.filter(ex => ex.state !== 1 && ex.state !== 3 && ex.state !== 5);
        //
        // for (const t of times) {
        //   totalHours += t.rawDuration.asHours();
        // }
        const rawH = Math.round(totalHours - 0.5);
        const leftMinutes = (totalHours - rawH) * 60.0;
        const rawM = Math.round(leftMinutes - 0.5);
        let rawS = Math.round(((leftMinutes - rawM) * 60.0));

        if (rawS < 0) {
            rawS = 0;
        }

        let minStr: string;
        if (rawM < 10) {
            minStr = '0' + rawM.toString();
        } else {
            minStr = rawM.toString();
        }

        let secStr: string;
        if (rawS < 10) {
            secStr = '0' + rawS.toString();
        } else {
            secStr = rawS.toString();
        }

        const resultTimeString = rawH.toString() + ':' + minStr + ':' + secStr;


        for (const res of this.Batch.resources) {
            for (const [key, value] of Object.entries(this.Batch.quantities)) {
                if (key === res.resource_type.id) {
                    res.resource_type.quantity = value;
                }
            }

            this.ressourceCost.push({
                id: res.id,
                restype: res.resource_type.group,
                name: res.name,
                resource: res.id,
                serial: res.serial,
                type: res.resource_type.name,
                totalValue: totalHours * res.resource_type.cost_per_hour * res.resource_type.quantity,
                TimeString: resultTimeString
            });
            if (res.resource_type.group === 'human') {
                this.humansResources.push({
                    id: res.id,
                    restype: res.resource_type.group,
                    name: res.name,
                    resource: res.id,
                    serial: res.serial,
                    type: res.resource_type.name,
                    totalValue: totalHours * res.resource_type.cost_per_hour * res.resource_type.quantity,
                    TimeString: resultTimeString
                });

            }
            if (res.resource_type.group === 'machine') {
                console.log(res);
                this.machinesResources.push({
                    id: res.id,
                    restype: res.resource_type.group,
                    cost_per_ton: res.resource_type.cost_per_ton,
                    power_consumption: res.resource_type.power_consumption !== null ? res.resource_type.power_consumption : 0,
                    name: res.name,
                    resource: res.id,
                    serial: res.serial,
                    type: res.resource_type.name,
                    depreciation: res.depreciation,
                    rent: res.rent,
                    workingTime: totalHours,
                    energy_consumption_measured: this.Batch.energy_consumption.hasOwnProperty(res.id),
                    energy_consumption: this.Batch.energy_consumption.hasOwnProperty(res.id) ? this.Batch.energy_consumption[res.id] * 1000 : 0,
                    checkedButtonEnergy: true,
                    TimeString: resultTimeString
                });
            }
        }

    }

    public getDepreciationCosts(): number {
        return this.machinesResources.reduce((accumulator, currentValue) => accumulator + currentValue.workingTime * currentValue.depreciation, 0);
    }

    public getRentCosts(): number {
        return this.machinesResources.reduce((accumulator, currentValue) => accumulator + currentValue.workingTime * currentValue.rent, 0);
    }

    public getTotalValueOfMachinesOverheadCosts(): number {
        return this.getTotalMachinesPowerConsumption() * this.energyPrice;
    }

    public getTotalMachinesPowerConsumption() {
        let totalValue = 0.0;

        for (const res of this.machinesResources) {
            totalValue += this.getMachinePowerConsumption(res);
        }
        return totalValue * this.overheadCosts / 100;
    }

    public getMachineWear(res: any) {
        return res.cost_per_ton * this.batchQuantity;
    }


    public getMachinePowerConsumption(res: any) {
        if (res.energy_consumption_measured) {
            if (res.checkedButtonEnergy) {
                return res.energy_consumption;
            } else {
                return res.power_consumption;
            }
        } else {
            return res.power_consumption;
        }
    }

    public getTotalMachineRes(res: any) {
        if (res.energy_consumption_measured) {
            if (res.checkedButtonEnergy) {
                return res.energy_consumption * this.energyPrice + this.getMachineWear(res);
            } else {
                return res.power_consumption * this.energyPrice + this.getMachineWear(res);
            }
        } else {
            return res.power_consumption * this.energyPrice + this.getMachineWear(res);
        }

    }

    public getTotalValueOfRes(): number {
        let pr = 0.0;

        for (const res of this.machinesResources) {
                pr += this.getTotalMachineRes(res);
        }

        for (const res of this.humansResources) {
             pr += res.totalValue;
        }

        return pr;
    }

    public getTotalValueOfResWithAdditionalCostsAndOverheadCosts(): number {
        return this.getTotalValueOfRes() + this.additionalCosts + this.getDepreciationCosts() + this.getRentCosts() + this.getTotalValueOfMachinesOverheadCosts();
    }


    public getCostsProTonne(): number {
        if (this.getProductTotalQuantity() == 0) {
            return 0;
        } else {
            return this.getTotalValueOfResWithAdditionalCostsAndOverheadCosts() / this.getProductTotalQuantity();
        }
    }


    public getTotalInput(): number {
        return (this.inputPrice * this.batchQuantity);
    }

    public saveNewQuantity() {
        if (this.Batch.quantity !== this.batchQuantity) {
            this.apiService.changeBatchQuantity(this.Batch.id, this.batchQuantity).subscribe((batch: any) => {
                if (batch.id) {
                    this.Batch.quantity = batch.quantity;
                    this.batchQuantity = this.Batch.quantity;
                    this.getProductCalculation();
                    this.getProductTotalValue();
                    this.getRessourceOutput();
                }
            }, () => {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Fehler beim Speichern';
                const dialogRef7 = this.dialog.open(MessageboxComponent, dialogConfig1);
                dialogRef7.afterClosed().subscribe(result => {
                    if (result === true) {
                        // NAVIGATE BACK TO BATCHES
                    }
                });
            });
        }
    }

    public bookTreatment() {


        if (this.Batch) {
            this.apiService.changeExecutionState(this.Batch.id, ExecutionActionTypes.BOOKING, this.getCostsProTonne()).subscribe((data: any) => {
                if (data) {
                    if (data.id) {

                        const dialogConfig1 = new MatDialogConfig();
                        dialogConfig1.disableClose = true;
                        dialogConfig1.autoFocus = true;
                        dialogConfig1.panelClass = 'dialogStyles';
                        dialogConfig1.data = 'Charge wurde gebucht';
                        const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                        dialogRef2.afterClosed().subscribe(result => {
                            if (result === true) {
                                // NAVIGATE BACK TO BATCHES
                                this.router.navigate(['/calculation']);
                            }
                        });


                    } else {
                        const errorTreatment = this.TreatmentErrors.find(ex => ex.code === data.errorcode);

                        if (errorTreatment) {
                            const dialogConfig1 = new MatDialogConfig();
                            dialogConfig1.disableClose = true;
                            dialogConfig1.autoFocus = true;
                            dialogConfig1.panelClass = 'dialogStyles';
                            dialogConfig1.data = errorTreatment.name;
                            const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                            dialogRef2.afterClosed().subscribe(result => {
                                if (result === true) {
                                    // NAVIGATE BACK TO BATCHES

                                }
                            });
                        }
                    }
                }
            });
        }
    }

    public goBack() {
        this.dataService.BatchToBook = null;
        this.router.navigate(['/calculation']);
    }

    onInput(value: any, $event) {
        if (value.value > 120000) {
            this.inputPrice = 999;
        }
        if (value.value < -120000) {
            this.inputPrice = 0;
        }

        if ($event.key === 'e') {
            this.inputPrice = 0;
        }

    }

    onOutput(value: any, outputproduct: any, $event) {
        if (value.value > 120000) {
            outputproduct.price = 999;
        }
        if (value.value < -120000) {
            outputproduct.price = 0;
        }
        if (value.value.indexOf('.') !== -1 && (value.value.length - value.value.indexOf('.') > 3)) {
            outputproduct.price = value.value.substring(0, value.value.length - 1);
        }

        if ($event.key === 'e') {
            outputproduct.price = 0;
        }

    }

    onResource(value: any, $event) {
        if (value.value > 120000) {
            this.energyPrice = 999;
        }
        if (value.value < -120000) {
            this.energyPrice = 0;
        }

        if (value.value.indexOf('.') !== -1 && (value.value.length - value.value.indexOf('.') > 3)) {
            this.energyPrice = value.value.substring(0, value.value.length - 1);
        }

        if ($event.key === 'e') {
            this.energyPrice = 0;
        }

    }

    public getOutputSubstitution(batch: any, mat: string) {

        if (mat) {
            if (batch.transfer) {
                if (batch.transfer.substitution) {
                    if (batch.transfer.substitution[mat]) {
                        if (batch.transfer.substitution[mat] !== 'none') {
                            return ' (' + batch.transfer.substitution[mat] + ')';
                        }
                    }
                }
            }
        }

        return '';
    }

    public getStartTimeOfBatch(): moment.Moment {
        if (this.Batch) {
            if (this.Batch.executions) {
                const executions = this.Batch.executions.filter(ex => ex.started).sort((a, b) => (a.started < b.started ? -1 : 1));
                if (executions.length > 0) {
                    return moment.utc(executions[0].started).local();
                }
            }
        }
        return null;
    }

    public getEndTimeOfBatch(): moment.Moment {
        if (this.Batch) {
            if (this.Batch.executions) {
                const executions = this.Batch.executions.filter(ex => ex.ended).sort((a, b) => (a.started < b.started ? -1 : 1));
                if (executions) {
                    return moment.utc(executions[executions.length - 1].ended).local();
                }
            }
        }
        return null;
    }

    public isWorkersInRessources() {
        return this.ressourceCost.some(el => el.restype === 'human');
    }

    public isMachinesInRessources() {
        return this.ressourceCost.some(el => el.restype === 'machine');

    }

    public onFocusOutEvent() {
        const costs = {
            value: this.additionalCosts,
            text: this.additionalCostsName
        };
        if (this.additionalCosts !== null) {
            this.apiService.addAdditionalCosts(this.Batch.id, costs).subscribe((data: any) => {
                if (data) {
                    console.log(data);
                }
            });
        }
    }

}
