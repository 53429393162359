import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {APIService} from '../../Services/api.service';
import {DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import {GlobalFunctionsService} from '../../Services/global-functions';
import {OptionBoxComponent} from "../../Dialogs/option-box/option-box.component";


moment.locale('de');

@Component({
    selector: 'app-weighing-filter',
    templateUrl: './weighing-filter.component.html',
    styleUrls: ['./weighing-filter.component.scss']
})
export class WeighingFilterComponent implements OnInit, OnDestroy {

    public minDate = new Date();
    public maxDate = new Date();
    user: any;
    productName: any;
    productNummer: any;
    batchName: any;
    invoicesList: any;
    displayedColumns: string[] = ['Zeit', 'Material', 'Batch', 'Fahrzeug Name', 'Fahrzeug Tara', 'Container Name', 'Container Tara', 'Waage', 'Brutto', 'Netto', 'Username', 'DeleteIcon'];
    weightMin: any;
    weightMax: any;
    disablePaginateButton = false;
    dateFormat = 'dd.MM.yyyy, HH:mm:ss';

    public showNext: boolean;
    public skip = 0;

    public scales: any[];
    public selectedScale: any;
    private subscription: Subscription;



    constructor(private functionsService: GlobalFunctionsService, public dialog: MatDialog, public apiService: APIService, public dataService: DataShareService, public router: Router,
                dateTimeAdapter: DateTimeAdapter<any>) {
        dateTimeAdapter.setLocale('de-DE'); // change locale to De
    }

    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.getScales();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }
        this.getScales();
        this.minDate.setDate(this.maxDate.getDate() - 14);

    }

    private ApiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.getScales();
        }
    }

    getScales() {
        this.selectedScale = null;
        this.apiService.getAvailableScales().subscribe((scales: any) => {
                if (scales) {
                    if (scales.length > 0) {
                        this.scales = scales;
                        console.log(this.scales);
                    }
                }
            },
            error => {
                this.functionsService.handleServerNotReachableError();
            });

    }

    public getBruttoWeight(netto, taramover, taracontainer) {
        return netto + taramover + taracontainer;
    }

    public paginateRight() {
        this.disablePaginateButton = true;
        this.skip = this.skip + 20;
        this.search();
    }

    public paginateLeft() {
        this.disablePaginateButton = true;
        this.skip = this.skip - 20;
        this.search();
    }

    buttonSearch() {
        this.skip = 0;
        this.search(true);

    }

    public deleteWeighing(element) {
        const dialogDeleteConfirmation = new MatDialogConfig();
        dialogDeleteConfirmation.disableClose = true;
        dialogDeleteConfirmation.autoFocus = true;
        dialogDeleteConfirmation.panelClass = 'dialogStyles';
        dialogDeleteConfirmation.data = {
            txt: 'Wollen Sie den Eintrag löschen?',
            option1: 'Ja',
            option2: 'Nein'
        };
        const dialogRef = this.dialog.open(OptionBoxComponent, dialogDeleteConfirmation);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                this.apiService.deleteWeighing(element).subscribe((weighing: any) => {
                    this.invoicesList =  this.invoicesList.filter(element => {
                        return element.id !== weighing.id;
                    })
                });
            } else if (result === 2) {

            }
        });


    }


    search(buttonSearch: boolean = false) {
        console.log(buttonSearch);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // tslint:disable-next-line:max-line-length
        this.subscription = this.apiService.getWeighings(buttonSearch === true  ? 0 : this.skip, 20, this.minDate.toISOString(), this.maxDate.toISOString(), this.productName, this.productNummer, this.selectedScale == null ? null : this.selectedScale.id, this.user, this.batchName, this.weightMin, this.weightMax).subscribe((weighings: any) => {
                if (weighings) {
                    this.showNext = weighings.length === 20;
                    this.disablePaginateButton = false;
                    console.log(weighings);
                    console.log(weighings);
                    this.invoicesList = weighings;
                    this.invoicesList.forEach(el => el.time = moment.utc( el.time).local());
                }
            },
            error => {
                this.functionsService.handleServerNotReachableError();
            });
    }

    getTotalNetto() {
        return this.invoicesList.map(t => t.net_weight).reduce((acc, value) => acc + value, 0);
    }

    getTotalBrutto() {
        let sum = 0;
        this.invoicesList.forEach(el => sum = sum + this.getBruttoWeight(el.net_weight, el.tare_weight_mover, el.tare_weight_container));
        return sum;
    }

    getTotalTara() {
        if (this.invoicesList.map(t => t.tare_weight_container).filter(item => item != null).reduce((acc, value) => acc + value, 0) === 0) {
            return 0;
        }
        return this.invoicesList.map(t => t.tare_weight_container).filter(item => item != null).reduce((acc, value) => acc + value, 0);
    }

    getTotalMoverWeight() {
        if (this.invoicesList.map(t => t.tare_weight_mover).filter(item => item != null).reduce((acc, value) => acc + value, 0) === 0) {
            return 0;
        }
        return this.invoicesList.map(t => t.tare_weight_mover).filter(item => item != null).reduce((acc, value) => acc + value, 0);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


}
