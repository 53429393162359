import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import * as moment from 'moment/moment';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {DateService} from '../../Services/date.service';
import {UserAccessService} from '../../Services/user-access.service';
import {map, switchMap} from 'rxjs/operators';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GlobalFunctionsService} from '../../Services/global-functions';
import {environment} from "../../../environments/environment";
import {EnvironmentTypes} from "../../Enums/environment-types";



@Component({
    selector: 'app-week-report',
    templateUrl: './week-report.component.html',
    styleUrls: ['./week-report.component.scss']
})
export class WeekReportComponent implements OnInit, OnDestroy {

    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public batches: any;

    @ViewChild(MatSort) sort: MatSort;
    public dataSource = new MatTableDataSource<any>();
    public batchesToShow: any[];
    public timeNow = Date.now();

    constructor(public dialog: MatDialog,
                public dateService: DateService,
                private Apiservice: APIService,
                public functionsService: GlobalFunctionsService,
                public userAccess: UserAccessService) {
    }

    private subscription$: Subscription;


    displayedColumns: string[] = ['Datum', 'Zeit', 'Dauer', 'Charge', 'Aufgabe', 'Produkt'];

    ngOnInit(): void {
        if (this.Apiservice.versioningFinished === true) {
            this.createSubscriptionFordate();
        } else {
            this.Apiservice.OnVersioningFinished.subscribe(() => {
                this.createSubscriptionFordate();
            });
        }
    }

    createSubscriptionFordate() {
        this.subscription$ = this.dateService.date.pipe(switchMap(data => this.generate(data))).subscribe(() => {
            },
            error => {
                this.functionsService.handleServerNotReachableError();
            }
        );
    }

    public round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    public getEndTime(dt: any, leadtime: number): moment.Moment {
        if (dt) {
            let d = moment(new Date(dt));
            d = moment(moment(d).add(leadtime, 'hours').clone()).clone();
            return d;
        }
        return null;
    }

    generate(now: moment.Moment): Observable<any[]> {
        const week = now.clone().week();
        const year = now.clone().year();
        this.batches = [];
        // GET BATCHES
        return this.Apiservice.getBatchListByCwReport(year, week).pipe(map((data: any) => {
            console.log(data);
            if (data) {
                console.log(data);
                this.batches = data;
                /*this.batches.sort((a, b) => (a.scheduled_start
                    < b.scheduled_start
                ) ? -1 : 1);*/

                this.batchesToShow = [];
                const startDay = now.clone().startOf('week');
                const endDay = now.clone().endOf('week').subtract(2, 'day');

                for (const bt of this.batches) {

                    if (bt.scheduled_start) {
                        bt.scheduled_start = moment.utc(bt.scheduled_start);
                        bt.scheduled_start = this.round(bt.scheduled_start, moment.duration(30, 'minutes'), 'round');
                        let endDate = moment(this.getEndTime(bt.scheduled_start, bt.lead_time).clone());
                        let endOfFoundDay;
                        // CALCULATE DATE
                        let current = moment(bt.scheduled_start);
                        console.log(current.format('HH:mm'));
                        if (current.day() === 5) {
                            endOfFoundDay = moment(current.clone().format('yyyy-MM-DD') + ' ' + '11:30:00');
                        } else {
                            endOfFoundDay = moment(current.format('yyyy-MM-DD') + ' ' + '16:00:00');
                        }
                        const d = new Date(bt.scheduled_start);
                        let hours = Math.trunc(bt.lead_time);
                        let minutes = Math.trunc((bt.lead_time - hours) * 60);
                        if (minutes > 0 && minutes <= 30) {
                            minutes = 30;
                        } else if (minutes > 30) {
                            hours = hours + 1;
                            minutes = 0;
                        }


                        const roundedLead = hours + (minutes / 60.0);
                        endDate = moment(d).add(roundedLead, 'hours').clone();


                        if (endDate.isAfter(endOfFoundDay)) {
                            while (endDate.isAfter(endOfFoundDay)) {
                                const endsts = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                                const startsts = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));
                                if (current.isBefore(endsts) && current.isAfter(startsts)) {
                                    let newEndDate;
                                    if (endOfFoundDay.format('yyyy-MM-DD HH:mm:ss') === current.format('yyyy-MM-DD HH:mm:ss')) {
                                        newEndDate = endOfFoundDay.add(30, 'minute').clone();
                                    }
                                    else {
                                        newEndDate = endOfFoundDay.clone();
                                    }
                                    this.batchesToShow.push({
                                        execution_time: bt.execution_time,
                                        comment : bt.comment ? bt.comment  : null,
                                        executions: bt.executions,
                                        execution_times: bt.execution_times,
                                        quantity: bt.quantity,
                                        output_weights: bt.output_weights,
                                        name : bt.name,
                                        id: bt.id,
                                        date: current.format('DD.MM.YYYY'),
                                        dateShown : current,
                                        start: (current.format('HH:mm')),
                                        end: (newEndDate.format('HH:mm')),
                                        process: bt.process,
                                        input: bt.input,
                                        output: bt.output,
                                        lead_time: bt.lead_time,
                                        enddate: endOfFoundDay.add(30, 'minute'),
                                        scheduleddate: bt.scheduled_start,
                                        haveAfter: true,
                                        haveBefore: !current.isSame(moment(bt.scheduled_start)),
                                        weighings: bt.weighings,
                                        transfer: bt.transfer
                                    });
                                }
                                // Calculate Timediff
                                const result = this.functionsService.findEndDate(endOfFoundDay, current, minutes, hours, endDate);
                                endOfFoundDay = result.endOfFoundDay;
                                current = result.current;
                                minutes = result.minutes;
                                hours = result.hours;
                                endDate = result.endDate;
                            }

                            const endst = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                            const startst = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));

                            if (endDate.isBefore(endst) && endDate.isAfter(startst)) {
                                let newEndDate;
                                if (endDate.format('yyyy-MM-DD HH:mm:ss') === current.format('yyyy-MM-DD HH:mm:ss')) {
                                    newEndDate = endDate.add(30, 'minute').clone();
                                }
                                else {
                                    newEndDate = endDate.clone();
                                }
                                this.batchesToShow.push({
                                    execution_time: bt.execution_time,
                                    comment : bt.comment ? bt.comment  : null,
                                    executions: bt.executions,
                                    execution_times: bt.execution_times,
                                    quantity: bt.quantity,
                                    output_weights: bt.output_weights,
                                    name : bt.name,
                                    id: bt.id,
                                    date: current.format('DD.MM.YYYY'),
                                    dateShown : current,
                                    start: (current.format('HH:mm')),
                                    end: (newEndDate.format('HH:mm')),
                                    process: bt.process,
                                    input: bt.input,
                                    output: bt.output,
                                    lead_time: bt.lead_time,
                                    day: current.day(),
                                    enddate: endDate.add(30, 'minute'),
                                    scheduleddate: bt.scheduled_start,
                                    haveAfter: false,
                                    haveBefore: !current.isSame(moment(bt.scheduled_start)),
                                    weighings: bt.weighings,
                                    transfer: bt.transfer
                                });
                            }
                        } else {
                            const endst = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                            const startst = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));
                            if (endDate.isBefore(endst) && endDate.isAfter(startst)) {
                                let newEndDate;
                                if (endDate.format('yyyy-MM-DD HH:mm:ss') === current.format('yyyy-MM-DD HH:mm:ss')) {
                                    newEndDate = endDate.add(30, 'minute').clone();
                                }
                                else {
                                    newEndDate = endDate.clone();
                                }
                                this.batchesToShow.push({
                                    execution_time: bt.execution_time,
                                    comment : bt.comment ? bt.comment  : null,
                                    executions: bt.executions,
                                    execution_times: bt.execution_times,
                                    quantity: bt.quantity,
                                    output_weights: bt.output_weights,
                                    name : bt.name,
                                    id: bt.id,
                                    date: bt.scheduled_start.format('DD.MM.YYYY'),
                                    dateShown : bt.scheduled_start,
                                    start: (bt.scheduled_start.format('HH:mm')),
                                    end: (newEndDate.format('HH:mm')),
                                    process: bt.process,
                                    input: bt.input,
                                    output: bt.output,
                                    lead_time: bt.lead_time,
                                    day: bt.scheduled_start.day(),
                                    enddate: endDate.add(30, 'minute'),
                                    scheduleddate: bt.scheduled_start,
                                    haveAfter: false,
                                    haveBefore: false,
                                    weighings: bt.weighings,
                                    transfer: bt.transfer
                                });
                            }
                        }
                    }
                }

                for (let i = 0; i < this.batchesToShow.length; i++) {
                    if (moment(this.batchesToShow[i].date, 'DD-MM-YYYY').week() !== week) {
                        this.batchesToShow.splice(i, 1);
                    }

                }

                for (const batch of this.batchesToShow) {
                    if (batch.scheduleddate.day() !== batch.enddate.day()) {
                        batch.scheduleddate = moment(
                            batch.scheduleddate.clone()
                                .add((batch.enddate.day() - batch.scheduleddate.day()), 'day')
                                .format('yyyy-MM-DD') + ' ' + '07:00:00');
                    }
                }

                this.batchesToShow.sort((a, b) => (a.dateShown < b.dateShown ? -1 : 1));
                console.log( this.batchesToShow);
                this.batchesToShow.forEach(batch => {
                    if (batch.executions) {
                        if (batch.executions.some(ex => ex.execution_type === 'teardown' || ex.execution_type === 'setup')) {
                            batch.hasTeardownOrSetup = true;
                        }

                    }
                });
                console.log( this.batchesToShow);
            } else {
                this.batches = [];
            }
            return [];
        }));
    }

    currentweek() {
        const now = moment();
        this.dateService.changeDate(now);
    }

    goCw(dir: number) {
        this.dateService.changeWeek(dir);
    }

    goYear(dir: number) {
        this.dateService.changeYear(dir);

    }

    public checkObjectHasProperties(obj: any): boolean {
        return Object.keys(obj).length > 0;
    }

    public getOutputSubstitution(batch: any, mat: string) {
        if (mat) {
            if (batch.transfer) {
                if (batch.transfer.substitution) {
                    if (batch.transfer.substitution[mat]) {
                        if (batch.transfer.substitution[mat] !== 'none') {
                            return ' (' + batch.transfer.substitution[mat] + ')';
                        }
                    }
                }
            }
        }
        return '';
    }

    public getWeightingList(executionBatch: any): any[] {
        const weighings = [];
        if (executionBatch.output_weights) {
            for (const key of Object.keys(executionBatch.output_weights)) {
                if (executionBatch.output_weights[key]) {
                    const wg = executionBatch.output_weights[key];
                    weighings.push({
                        id: key,
                        name: wg.name,
                        weight: wg.weight,
                    });
                }
            }
        }
        return weighings;
    }

    public getWeightSumForBatch(executionBatch: any): number {
        let total = 0;
        for (const weighing of executionBatch.weighings) {
            if (!executionBatch.haveBefore) {
                const wg = weighing.net_weight;
                total += wg;
            }
        }
        return total;
    }

    public getWeightSumTotal(batches: any[]): number {
        let total = 0;
        batches.forEach(batch => {
            !batch.haveBefore ? total += this.getWeightSumForBatch(batch) : total = total;
        });
        return total;
    }

    public getExecutionsTimeTotal(batches: any[]): any {
        const map1 = new Map();
        batches.forEach(batch => {
            if (!batch.haveBefore)  {
                for (const key in batch.execution_times) {
                    if (map1.get(key) !== undefined) {
                        map1.set(key, map1.get(key) + this.getCurrentTime(batch, key));
                    }
                    else {
                        map1.set(key, this.getCurrentTime(batch, key));
                    }
                }
            }
        });
        return map1;
    }

    public getTeardownSetupTimeTotal(batches: any[]): string {
        let totalTeardownOrSetup = 0;
        batches.forEach(batch => {
            if (!batch.haveBefore && batch.hasTeardownOrSetup)  {
                totalTeardownOrSetup += this.getTeardownAndSetupTime(batch);
            }
        });
        return this.functionsService.durationToString(totalTeardownOrSetup);
    }

    public getExecutionTimeTotal(batches: any[]): string {
        let total = 0;
        batches.forEach(batch => {
            !batch.haveBefore ? total += batch.execution_time : total = total;
        });
        return this.functionsService.durationToString(total);
    }

    public getOutputSumTotal(batches: any[]): number {
        let total = 0;
        batches.forEach(batch => {
            !batch.haveBefore && batch.process.process_category !== 'maintenance' ? total += batch.quantity : total = total;
        });
        return total;
    }

    public getCurrentTime(batch: any, type: unknown): number {

        if (batch.executions) {
            // SUM ALL
            let seconds = 0.0;
            for (const et of batch.executions.filter(ex => ex.execution_type === type)) {
                const start = moment.utc(et.started).local();
                const end = et.ended ? moment.utc(et.ended).local() : moment.utc(this.timeNow).local();

                seconds += end.diff(start, 'seconds');
            }

            return Math.round(seconds);
        }

        return 0;
    }

    public getTeardownAndSetupTime(batch: any): number {

        if (batch.executions) {
            // SUM ALL
            let seconds = 0.0;
            for (const et of batch.executions.filter(ex => ex.execution_type === 'teardown' || ex.execution_type === 'setup')) {
                const start = moment.utc(et.started).local();
                const end = et.ended ? moment.utc(et.ended).local() : moment.utc(this.timeNow).local();

                seconds += end.diff(start, 'seconds');
            }

            return Math.round(seconds);
        }

        return 0;
    }

    public convertExecutionName(executionName: unknown): string {
        switch (executionName) {
            case 'processing': {
                return 'Aufbereiten ';
            }
            case 'pause': {
                return 'Pause ';
            }
            case 'maintenance': {
                return 'Instandhaltung ';
            }
            case 'malfunction': {
                return 'Störung ';
            }
            default: {
                return 'default';
            }
        }
    }


    ngOnDestroy() {
        this.subscription$.unsubscribe();

    }
}
